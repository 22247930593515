<template>
	<div id="download">
		<van-row>
			<van-col span="24">
				<van-nav-bar title="软件下载" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
			</van-col>
		</van-row>
		<van-row>
			<van-col span="24">
				<van-tabs v-model="active" animated>
					<van-tab title="安卓手机">
						<van-row type="flex" justify="center">
							<van-col span="19">
								<van-cell-group>
									<van-field v-model="value" label="下载地址"/>
								</van-cell-group>
							</van-col>
							<van-col span="4">
								<van-button type="primary" size="small">复制地址</van-button>
							</van-col>
						</van-row>
					</van-tab>
					<van-tab title="苹果手机">
						
					</van-tab>
					<van-tab title="MAC电脑">
						<van-row type="flex" justify="center">
							<van-col span="19">
								<van-cell-group>
									<van-field v-model="value" label="下载地址"/>
								</van-cell-group>
							</van-col>
							<van-col span="4">
								<van-button type="primary" size="small">复制地址</van-button>
							</van-col>
						</van-row>
					</van-tab>
					<van-tab title="Windows电脑">
						<van-row type="flex" justify="center">
							<van-col span="19">
								<van-cell-group>
									<van-field v-model="value" label="下载地址"/>
								</van-cell-group>
							</van-col>
							<van-col span="4">
								<van-button type="primary" size="small">复制地址</van-button>
							</van-col>
						</van-row>
					</van-tab>
				</van-tabs>
			</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				value: 'https://123vip.lanzous.com/icv1dhe',
			};
		},
	};
</script>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	#home {
		text-align: center;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
